.dataTables_wrapper {
    .dataTables_length {
        padding: 15px;

        select {
            padding: 0.575rem 1rem;
            font-size: 0.875rem;
        }
    }

    .dataTables_filter {
        padding: 15px;

        input {
            padding: 0.575rem 1rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
        }
    }
    .dataTables_info,
    .dataTables_paginate{
            padding: 15px;
    }
    .row{
        align-items: center ;
    }

}

.card.table-card{
    .dataTables_wrapper {
        tr:last-child td{
            border-bottom: 1px solid $border-color;
        }
    }
}