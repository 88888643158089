.authentication-signin{
    .auth-content{
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        &>.row{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px){
    .authentication-signin{
        .auth-content{
            padding: 30px 10px;
        }
    }
}