@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";
$menu-styler: 300px;
$pct-colors: ();
$pct-colors: map-merge(("primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
  ),
  $pct-colors);

//     header css start
.site-header {
  background: $header-background;
  box-shadow: $header-shadow;
  color: $header-color;
  min-height: $header-height;
  position: fixed;
  left: $sidebar-width;
  right: 0;
  z-index: 1025;

  .header-wrapper {
    display: flex;
    align-items: center;
    padding: 0 25px;
  }

  .header-menu-nav {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    & .hdr-itm {
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px 0;
    }

    .dropdown-toggle::after {
      content: unset;
    }

    .hdr-itm:not(:last-of-type) {
      margin: 0 15px 0 0;
    }
  }

  .app-head-link {
    color: $primary;
    display: inline-flex;
    align-items: center;
    padding: 0.6rem 0.7rem;
    position: relative;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid $header-item-border-color;

    & i {
      font-size: 18px;
    }

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  .h-dots {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 10px;
    right: 12px;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  .app-head-link .avtar {
    width: 42px;
    height: 42px;
    font-size: 16px;
    border-radius: 10px;
    background: shift-color($primary, $soft-bg-level);
    color: $primary;
  }

  .user-dropdown .app-head-link {
    padding: 0;
    border-color: transparent;
  }

  .app-head-link .drop-arrow {
    margin-left: 5px;
    font-size: 15px;
  }

  .dropdown-menu {
    padding: 0 0;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
    border: none;

    & .dropdown-item {
      padding: 0;

      & .drp-link {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        color: $header-color;
        line-height: 1;

        & i {
          font-size: 16px;
          margin-right: 10px;
        }

        & svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        & .badge {
          margin-left: 5px;
        }
      }
    }
  }

  .dropdown-menu {
    transform: none !important;
    top: 100% !important;
  }

  .ntf-dropdown .dropdown-menu {
    min-width: 400px;
  }

  .ntf-dropdown .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 15px;
  }

  .ntf-dropdown .notification-body {
    padding: 0px 20px 15px;
    position: relative;
    max-height: calc(100vh - 360px);
    height: 100%;
  }

  .ntf-dropdown .notification-footer {
    padding: 15px 15px 15px;
  }

  .dropdown-divider {
    margin: 0;
  }

  .header-search {
    width: 65%;
    .input-group{
      input{
        padding: 0.575rem 3rem 0.575rem 1rem;
      }
    }
    .input-group .search-btn {
      position: absolute;
      top: 7px;
      left: auto;
      right: 15px;
      z-index: 6;

      svg {
        display: inline-block;
      }
    }
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: filter 0.15s linear;
  width: 20px;
  height: 20px;

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 18px;
    height: 2px;
    background-color: $primary;
    border-radius: 4px;
    position: absolute;
    transition: transform 0.15s ease;

    &::after,
    &::before {
      content: '';
      width: 18px;
      height: 2px;
      background-color: $primary;
      border-radius: 4px;
      position: absolute;
      display: block;
      transition: transform 0.15s ease;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }
}

.site-header .hdr-itm.mob-hamburger {
  display: none;
}

// header css end



//Common layout css start

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: darken($body-bg, 25%);
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.navbar-content {
  position: relative;
}

.ps__rail-y {
  z-index: 5;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent;
}

.ps__thumb-y {
  background: darken($body-bg, 25%);
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
  background: darken($body-bg, 20%);
}

// Common layout css end
// themes color preference start
.theme-roller {
  position: fixed;
  right: -#{$menu-styler};
  top: 90px;
  z-index: 1025;
  transition: all 0.15s ease-in-out;

  &.active {
    right: 0;
  }

  .open-button {
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
    transition: all 0.15s ease-in-out;

    .btn {
      padding: 18px 17px;
      display: block;
      border-radius: 10px 0 0 10px;
      box-shadow: 0 9px 9px -1px rgba(166, 161, 201, 0.4);

      +.btn {
        margin-top: 8px;
      }
    }
  }

  .theme-roller-content {
    width: $menu-styler;
    position: relative;
    top: 0;
    right: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 0px 0px 0px 15px;
    box-shadow: 0 9px 9px -1px rgba(166, 161, 201, 0.4);

    .presets-header {
      padding: 20px 30px;
      border-bottom: 1px solid $border-color;
    }

    .presets-list {
      padding: 20px 20px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.doc-img,
.themes-preference {
  display: block;
  position: relative;
  padding: 0;
  margin-top: 10px;

  >a {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    display: inline-block;
    background: $body-bg;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);

    +a {
      margin-left: 5px;
    }

    span {
      width: 100%;
      position: absolute;

      &:after,
      &:before {
        content: "";
        height: 100%;
        position: absolute;
      }

      &:before {
        width: 32%;
        left: 0;
        background: $sidebar-background;
      }

      &:after {
        width: 70%;
        right: 0;
        background: $body-bg;
      }
    }

    >span:nth-child(1) {
      height: 40%;
      top: 0;

      &:after {
        background: $header-background;
      }
    }

    >span:nth-child(2) {
      height: 66%;
      bottom: 0;
    }
  }

  &.header-color {

    @each $color,
    $value in $pct-colors {
      >a {
        &[data-value="bg-#{$color}"] {
          >span:nth-child(1) {
            &:after {
              background: $value;
            }
          }
        }
      }
    }
  }

  &.brand-color {

    @each $color,
    $value in $pct-colors {
      >a {
        &[data-value="bg-#{$color}"] {
          >span:nth-child(1) {
            &:before {
              background: $value;
            }
          }
        }
      }
    }
  }

  $i: 1;

  &.themes-preference {

    @each $name,
    $value in $themes-preferences {
      >a {
        &[data-value="theme-#{$i}"] {
          background: map-get($value, "primary-grd"), map-get($value, "primary");
        }
      }

      $i: $i+1;
    }
  }
}

.doc-img {
  >a {
    width: 100px;
    height: 65px;
  }
}

// themes color preference end