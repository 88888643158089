/**======================================================================
Template Name: Wedash Bootstrap 5 Admin Template
Author: Techneinfosys
Support: [support link]
File: style.css
=================================================================================== */

// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

@import "node_modules/bootstrap/scss/bootstrap";

// main framework
@import "themes/general";
@import "themes/generic";


// Layouts
@import "themes/layouts/sidenav";
@import "themes/layouts/site-header";
@import "themes/layouts/common";

// widget
@import "themes/component/widget";

// Componants
@import "themes/component/avtars";
@import "themes/component/tables";
@import "themes/component/forms";
@import "themes/component/buttons";
@import "themes/component/dropdowns";
@import "themes/component/progress";
@import "themes/component/modal";

// Plugins
@import "themes/plugin/data-tables";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/switche";
@import "themes/plugin/datatables-bs";

// pages
@import "themes/pages/pages";
@import "themes/pages/ecommerce";
@import "themes/pages/authentication";