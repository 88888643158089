// ============================
//     Sidebar css start
// ============================
   // sidebar color varient Start
   $sidebar-c-background: #fff !default;
   $sidebar-c-color: #67748e !default;
   $sidebar-c-active-color: $primary !default;
   $sidebar-c-caption: #67748e !default;
   $sidebar-c-shadow: -11px 13px 20px 0 rgba(50, 57, 61, 0.08) !default;

// Sidebar css start
.app-sidebar{
  background: $sidebar-background;
  box-shadow: $sidebar-shadow;
  width: $sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026;
  .brand-link{
    padding: 1rem 1.5rem 1.5rem;
    display: block;
    .b-brand{
      display: block;
      img{
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .navbar-content{
    position: relative;
    height: calc(100vh - 70px);
    padding: 10px 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .nav-icon{
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    i {
      font-size: 18px;
      color: $gray-600;
    }
    > svg {
      width: 18px;
      height: 18px;
      color: $gray-600;
    }
  }
  .app-navbar {
    > .nav-item {
      > .nav-link {
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px 10px 10px 7px;
        border-radius: 5px;
      }
    }
  }
  .app-navbar {
    .nav-link {
      display: flex;
      align-items: center;
      padding: 13px 20px;
      color: $gray-600;
      position: relative;
      line-height: 1;
      margin: 7px 0;
      font: {
        size: 14px;
        weight: 600;
      }
      .nav-text{
        flex: 1;
      }
      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
        color: $gray-600;
      }
    }
  }

  .nav-caption{
    display: block;
    padding: 20px 20px 8px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 11px;
    color: $gray-600;
    font-weight: 700;
  }
   
  .nav-submenu{
    .nav-link {
      display: block;
      font-weight: 500;
      margin: 4px 0;
      padding: 10px 30px 10px 45px;
      .nav-arrow{
        float: right;
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }

  
    .nav-submenu{
      .nav-link {
        padding: 10px 30px 10px 85px;
      }

      .nav-submenu{
        .nav-link {
          padding: 10px 30px 10px 105px;
        }
      }
    }
  }
  .nav-arrow svg{
    width: 18px;
    height: 18px;
    display: inline-block;
  }
  .nav-submenu {
    position: relative;
    .nav-item {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 26px;
        top: 15px;
        width: 8px;
        height: 8px;
        border: 2px solid $sidebar-color;
        border-right-color: $gray-600;
        border-radius: 50%;
        z-index: 1;
        transform: rotate(45deg);
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        color: $sidebar-icon-color;
      }

      &.active {
        &:before {
          // color: $primary;
          transform: rotate(230deg);
        }
      }

      &:hover {
        &:before {
          transform: rotate(230deg);
        }
      }
    }

    .nav-submenu {
      > .nav-item {
        &:before {
          left: 60px;
        }
      }

      .nav-submenu {
        > .nav-item {
          &:before {
            left: 80px;
          }
        }
      }
    }
  }

  .nav-item {
    &.disabled {
      a {
        cursor: not-allowed;
        user-select: none;
      }
    }

    &.active,
    &:focus,
    &:hover {
      > .nav-link {
        color: $sidebar-main-active-color;

        .nav-icon {
          i.material-icons-two-tone {
            background-color: $sidebar-main-active-color;
          }
          i,
          svg {
            color: $sidebar-main-active-color;
          }
        }
      }
    }

    > .nav-submenu.nav-item {
      &.active,
      &:focus,
      &:hover {
        > .nav-link {
          font-weight: 700;
          color: $sidebar-active-color;
          .nav-icon {
            i,
            svg {
              color: $sidebar-active-color;
            }
          }
        }
      }
    }
  }

  .nav-hasmenu {
    &.nav-provoke {
      > .nav-submenu {
        display: block;
        // display: block !important;
      }

      > .nav-link {
        > .nav-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

}

.app-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}


.auth-wrapper {
  ~ .theme-roller {
    display: none;
  }
}
  // Sidebar css end
  
