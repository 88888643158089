// Common layout css start  
.content-container{
    position: relative;
    top: $header-height;
    margin-left: $sidebar-width;
    min-height: calc(100vh - #{$header-height});
    .page-content{
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
    }

    .content-header + .row {
        padding-top: 25px;
    }

    .content-header + .page-content{
        padding-top: 25px;
    }
}
.content-header{
    & h1{
        font-size: 1.8rem;
    }
}
.content-header .breadcrumb{
    margin-bottom: 0;
    .breadcrumb-item{
        line-height: 1;
        &::before{
            display: inline-block;
        }
    }
}

.breadcrumb svg.feather {
    width: 16px;
    height: 16px;
    vertical-align: baseline;
    display: inline-block;
}

//Card css start
.card{
    margin-bottom: 24px;
    box-shadow: $card-shadow;
    transition: box-shadow 0.2s ease-in-out;
    .card-header {
        padding: 20px 20px;
        border-bottom: 1px solid $border-color;
        h4{
            margin-bottom: 0;
        }
    }
    .card-body{
        padding: 1rem;
    }

    @each $color, $value in $theme-colors {
        &.bg-#{$color} {
          box-shadow: 0 9px 9px -1px transparentize($value, 0.7);
        }
      }
}

.card-group {
    .card{
        box-shadow: none;
    }
}
.position-fixed.top-0.end-0{
    top: $header-height !important;
}
// site footer start
.app-footer{
    position: relative;
    top: 60px;
    margin-left: $sidebar-width;
    .footer-wrapper{
        padding: 10px 15px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
// kanban
.kanban-wrapper {
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}
.kanban-wrapper > .col {
    width: 390px;
    flex: 0 0 auto;
}
.notifier-container{
    z-index: 99999 !important;
}
// Common responsive css start

@media (max-width: 1024px){
    .site-header .hdr-itm.mob-hamburger {
        display: inline-block !important;
    }
    .app-sidebar{
      left: -#{$sidebar-width};
      box-shadow: none;
      transition: all 0.15s ease-in-out;
      &.mob-sidebar-active{
        left: 0;
      }
    }
    .page-content-wrapper .content-container{
      margin-left: 0;
    }
    .site-header{
      top: 0;
      left: 0 !important;
      transition: all 0.15s ease-in-out;
    }
    .app-footer{
      margin-left: 0 !important;
    }
    .app-sidebar.mob-sidebar-active .app-navbar-wrapper{
      position: relative;
      background: inherit;
      z-index: 5;
    }
}
@media (max-width: 767px){
    .content-container .page-content{
        padding-left: 15px;
        padding-right: 15px;
    }
    .site-header .header-wrapper{
        padding: 0 15px;
    }
    .site-header .ntf-dropdown .notification-body{
        max-height: calc(100vh - 220px);
    }
}
@media (max-width: 575.98px){
    .card.table-card .card-header.d-flex{
        flex-wrap: wrap;
    }
    .card.table-card .card-header.d-flex .btn-group{
        flex: 0 0 100%;
        margin-top: 15px;
    }
    .app-footer .footer-wrapper{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .site-header .header-menu-nav .hdr-itm{
        position: static;
    }
    .site-header .ntf-dropdown .dropdown-menu{
        left: 0 !important;
        right: 0 !important;
        min-width: 100% !important;
    }
  
}