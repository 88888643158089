// ============================
//     Button css start
// ============================

.btn {
    font-size: 14px;
  
    i {
      font-size: 18px;
    }
  
    svg {
      width: 18px;
      height: 18px;
    }
  
    &[class*="btn-light-"] {
      box-shadow: none;
    }
  
    &[class*="btn-outline-"]:not(:hover) {
      box-shadow: none;
    }
  
    &.btn-shadow {
      box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3);
    }
  
    &.btn-sm {
      i {
        font-size: 14px;
      }
    }
    &[class*="btn-gradient-"] {
      border: none !important;
      // box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3);
    }
  }
  @each $color, $value in $theme-colors {
    .btn-light-#{$color} {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
  
      &:hover {
        background: $value;
        color: #fff;
        border-color: $value;
      }
  
      &.focus,
      &:focus {
        background: $value;
        color: #fff;
        border-color: $value;
      }
  
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > &.dropdown-toggle {
        background: $value;
        color: #fff;
        border-color: $value;
      }
    }
  
    .btn-check:active,
    .btn-check:checked {
      + .btn-light-#{$color} {
        background: $value;
        color: #fff;
        border-color: $value;
      }
    }
  }
  @each $color, $value in $theme-colors {
    $tmpclr: adjust-hue($value, 50deg);
    .btn-gradient-#{$color} {
      background: linear-gradient(141.55deg, $value, $tmpclr);
      color: color-contrast($value);
  
      &:hover,
      &.focus,
      &:focus {
        background: linear-gradient(141.55deg, $tmpclr, $value);
        color: color-contrast($value);
      }
  
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show > &.dropdown-toggle {
        background: linear-gradient(141.55deg, $tmpclr, $value);
        color: color-contrast($value);
      }
    }
  
    .btn-check:active,
    .btn-check:checked {
      + .btn-gradient-#{$color} {
        background: linear-gradient(141.55deg, $tmpclr, $value);
        color: color-contrast($value);
      }
    }
  }
  
  .btn-example-page{
    .btn{
        margin: 0.25rem 0.125rem;
    }
    .btn-group>.btn{
        margin: 0;
    }
  }

  .btn-badge-position{
    margin-bottom: -20px;
    .btn{
        margin-right: 20px;
        margin-bottom: 20px;
    }
  }