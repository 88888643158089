// e-commerce start
.product-slider {
    .carousel-indicators {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      & [data-bs-target] {
        margin-left: 8px;
        margin-right: 8px;
      }
      img {
        width: 100%;
      }
    }
    img {
      border-radius: 15px;
    }
  }
  .progress {
    height: 6px;
}