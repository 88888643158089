// =======================================
//     List of variables for layout
// =======================================

$header-height: 64px;
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;

// header
$header-background: #fff;
$header-color: #525b69;
$header-shadow: 0 10px 20px 0 rgba(50, 57, 61, 0.08);
$brand-color: #161c25;
$header-item-border-color: #DFECFD;


// Menu
$sidebar-background: #fff;
$sidebar-color: #ced4dc;
$sidebar-icon-color: #778290;
$sidebar-main-active-color: #fff;
$sidebar-active-color: $primary;
$sidebar-caption: $header-color;
$sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

// horizontal menu
$topbar-height: 60px;
$topbar-background: #1c232f;
$topbar-color: #b5bdca;
$header-submenu-background: #fff;
$header-submenu-color: #1c232f;

// card block
$card-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05);

$soft-bg-level: -80%;

$themes-preferences: (

  theme-1: (
    body: #f8f9fa,
    primary: #7928ca,
    primary-grd:linear-gradient(141.55deg,#7928ca 3.46%, #ff0080 99.86%),
  ),
  theme-2: (
    body: #f8f9fa,
    primary: #2c3e50,
    primary-grd: linear-gradient(to right, #2c3e50, #4ca1af),
  ),
  theme-3: (
    body: #f8f9fa,
    primary: #8905D3,
    primary-grd: linear-gradient(270deg, #37006F 0%, #8905D3 100%),
  ),
  theme-4: (
    body: #f8f9fa,
    primary: #584ed2,
    primary-grd:
      linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685ee5 99.86%),
  ),

);
