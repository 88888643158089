// ============================
//     Form css start
// ============================

.form-group {
    margin-bottom: 1.3rem;
  
    label {
      font-weight: 600;
    }
  }
  .form-control{
    &.flatpickr-input[readonly]{
      background: $input-bg;
    }
  }
  .input-group-text svg {
    width: 18px;
    height: 18px;
  }
  
  select.form-control {
    appearance: none;
    background: #{$input-bg} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
      no-repeat right 0.75rem center/18px 25px;
  
    &[data-multiselectsplitter-firstselect-selector],
    &[data-multiselectsplitter-secondselect-selector] {
      background: none;
    }
  }
  
  .bootstrap-select {
    > .dropdown-toggle.btn-light,
    > .dropdown-toggle.btn-secondary,
    > .dropdown-toggle.btn-default {
      border-color: $input-border-color !important;
      box-shadow: none;
      background: $input-bg !important;
      color: $input-color;
  
      &:focus {
        outline: none !important;
      }
    }
  }
  
  @each $color, $value in $theme-colors {
    .form-check {
      .form-check-input {
        &.input-#{$color} {
          &:checked {
            border-color: $value;
            background-color: $value;
          }
        }
  
        &.input-light-#{$color} {
          &:checked {
            border-color: shift-color($value, $soft-bg-level);
            background-color: shift-color($value, $soft-bg-level);
  
            &[type="checkbox"] {
              background-image: escape-svg(
                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$value}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>")
              );
            }
  
            &[type="radio"] {
              background-image: escape-svg(
                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$value}'/></svg>")
              );
            }
          }
        }
  
        &.input-#{$color},
        &.input-light-#{$color} {
          &:focus {
            &[type="checkbox"],
            &[type="radio"] {
              box-shadow: 0 0 0 0.2rem rgba($value, 0.25);
            }
          }
        }
      }
  
      &.form-switch {
        .form-check-input.input-light-#{$color} {
          &:checked {
            background-image: escape-svg(
              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$value}'/></svg>")
            );
          }
        }
      }
    }
  }
  
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-right: none;
  }
  
  // v1 Start
  
  .form-v1 {
    .form-label-group {
      position: relative;
      margin-bottom: 1rem;
    }
  
    .form-label-group > input,
    .form-label-group > label {
      height: 3.125rem;
      padding: 0.75rem;
    }
  
    .form-label-group > label {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      color: #495057;
      pointer-events: none;
      cursor: text;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      transition: all 0.1s ease-in-out;
    }
  
    .form-label-group input::-webkit-input-placeholder {
      color: transparent;
    }
  
    .form-label-group input::-ms-input-placeholder {
      color: transparent;
    }
  
    .form-label-group input::-moz-placeholder {
      color: transparent;
    }
  
    .form-label-group input::placeholder {
      color: transparent;
    }
  
    .form-label-group input:not(:-moz-placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
    }
  
    .form-label-group input:not(:placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
    }
  
    .form-label-group input:not(:-moz-placeholder-shown) ~ label {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 12px;
      color: #777;
    }
  
    .form-label-group input:not(:placeholder-shown) ~ label {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 12px;
      color: #777;
    }
  }
  
  // v1 end
  // Date picker start
  
  .datepicker {
    width: 265px;
    padding: 10px;
  
    &.dropdown-menu {
      padding: 10px;
    }
  
    &.datepicker-orient-top {
      margin-top: 8px;
    }
  
    table {
      width: 100%;
    }
  
    td,
    th {
      font-weight: regular;
      width: 35px;
      height: 35px;
    }
  
    thead {
      th {
        color: $headings-color;
  
        &.datepicker-switch,
        &.next,
        &.prev {
          font-weight: 500;
          color: $body-color;
  
          i {
            font-size: 1.2rem;
            color: $body-color;
  
            &:before {
              line-height: 0;
              vertical-align: middle;
            }
          }
  
          &:hover {
            background: $body-bg !important;
          }
        }
  
        &.dow {
          color: $body-color;
          font-weight: 500;
        }
      }
    }
  
    tbody {
      tr > td {
        &.day {
          color: $body-color;
  
          &:hover {
            background: $body-bg;
            color: $body-color;
          }
  
          &.old {
            color: $body-color;
          }
  
          &.new {
            color: $body-color;
          }
  
          &.active,
          &.active:hover,
          &.selected,
          &.selected:hover {
            background: $primary;
            color: #fff;
          }
  
          &.today {
            position: relative;
            background: $primary !important;
            color: #fff !important;
  
            &:before {
              content: "";
              display: inline-block;
              border: solid transparent;
              border-width: 0 0 7px 7px;
              border-bottom-color: #ffffff;
              border-top-color: $body-color;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
          }
  
          &.range {
            background: $body-bg;
          }
        }
  
        span.hour,
        span.minute,
        span.month,
        span.year {
          color: $body-color;
  
          &:hover {
            background: $body-bg;
          }
  
          &.active,
          &.active.focused:hover,
          &.active:hover,
          &.focused,
          &.focused:hover {
            background: $primary;
            color: #fff;
          }
        }
      }
    }
  
    tfoot {
      tr > th {
        &.clear,
        &.today {
          font-weight: 500;
  
          &:hover {
            background: $body-bg;
          }
        }
      }
    }
  
    &.datepicker-inline {
      border: 1px solid $border-color;
    }
  }
  
  // Date picker end
  // Time picker start
  
  .bootstrap-timepicker-widget {
    left: auto;
    width: 175px;
  
    .bootstrap-timepicker-hour,
    .bootstrap-timepicker-meridian,
    .bootstrap-timepicker-minute,
    .bootstrap-timepicker-second {
      border: none;
      box-shadow: none;
    }
  
    table td {
      > a {
        border: 0;
  
        i {
          font-size: 1.1rem;
        }
      }
    }
  }
  
  .bootstrap-timepicker-widget {
    .bootstrap-timepicker-hour,
    .bootstrap-timepicker-meridian,
    .bootstrap-timepicker-minute,
    .bootstrap-timepicker-second {
      color: $headings-color;
    }
  
    table td > a {
      &:hover {
        background: $body-bg;
      }
    }
  }
  
  // Time picker end
  // tagify start
  
  .tagify__tag {
    --tag-bg: #{$body-bg};
    --tag-hover: #{transparentize($primary, 0.7)};
    --tag-remove-bg: #{transparentize($danger, 0.7)};
    --tag-remove-btn-bg--hover: #{$danger};
  
    @each $color, $value in $theme-colors {
      &.tagify__tag--#{$color} {
        --tag-bg: #{transparentize($value, 0.8)};
  
        div {
          .tagify__tag-text {
            color: $value;
          }
        }
      }
    }
  }
  
  // tagify end
  // switch start
  
  .bootstrap-switch {
    border-color: $border-color;
  
    .bootstrap-switch-handle-off,
    .bootstrap-switch-handle-on {
      &.bootstrap-switch-default {
        background: $body-bg;
      }
  
      @each $color, $value in $theme-colors {
        &.bootstrap-switch-#{$color} {
          color: #fff;
          background: $value;
        }
      }
    }
  }
  
  // switch end
  // bootstrap-select start
  
  .bootstrap-select {
    > .dropdown-toggle.bs-placeholder.btn[class*="btn-"]:not(.btn-light) {
      color: #fff;
    }
  }
  
  // bootstrap-select end
  // typeahead start
  
  .typeahead .twitter-typeahead {
    display: block !important;
  }
  
  .scrollable-dropdown-menu {
    .tt-menu {
      max-height: 150px;
      overflow-y: auto;
    }
  }
  
  .tt-menu {
    padding: 10px 0;
    font-size: $font-size-base;
    background: #fff;
    min-width: 10rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  
    .tt-suggestion {
      padding: 4px 20px;
      border-radius: 2px;
  
      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparentize($primary, 0.97);
        color: $primary;
      }
    }
  }
  
  // typeahead end
  // typeahead start
  
  .sticky-action {
    .card-header {
      background: #fff;
      z-index: 1;
    }
  
    &.sticky {
      .card-header {
        top: 0;
        position: fixed;
        box-shadow: 0 1px 15px 1px rgba(57, 82, 107, 0.15);
        left: calc(#{$sidebar-width} + 30px);
        right: 30px;
  
        @media (max-width: 1024px) {
          top: calc(#{$topbar-height} + 10px);
        }
      }
    }
  }
  
  // typeahead end
  // dropzone start
  // Base
  
  .dropzone {
    min-height: auto;
  }
  
  .dropzone {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border: 2px dashed $border-color;
    border-radius: $border-radius;
  
    .dropzone-msg-title {
      color: $body-color;
      margin: 0 0 5px;
      padding: 0;
      font-weight: 500;
      font-size: 1.2rem;
    }
  
    .dropzone-msg-desc {
      color: $body-color;
      font-weight: 400;
      font-size: 1rem;
    }
  
    .dz-preview {
      .dz-image {
        border-radius: $border-radius;
      }
    }
  
    // State colors
    @each $color, $value in $theme-colors {
      &.dropzone-#{$color} {
        border-color: $value;
      }
    }
  }
  
  // Multipe upload
  
  .dz-started {
    .dropzone-msg {
      display: none;
    }
  }
  
  .dropzone-multi {
    border: 0;
    padding: 0;
  
    .dz-message {
      display: none;
    }
  
    .dropzone-panel {
      .dropzone-remove-all,
      .dropzone-upload {
        display: none;
      }
    }
  
    .dropzone-item {
      background: $body-bg;
      border-radius: $border-radius;
      margin: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 14px;
  
      .dropzone-progress {
        width: 20%;
  
        .progress {
          height: 0.5rem;
          transition: all 0.2s ease-in-out;
        }
      }
  
      .dropzone-file {
        .dropzone-filename {
          font-size: 0.9rem;
          font-weight: 500;
          color: $body-color;
          text-overflow: ellipsis;
          margin-right: 0.5rem;
  
          b {
            font-size: 0.9rem;
            font-weight: 500;
            color: $body-color;
          }
        }
  
        .dropzone-error {
          margin-top: 0.25rem;
          font-size: 0.9rem;
          font-weight: 400;
          color: $danger;
          text-overflow: ellipsis;
        }
      }
  
      .dropzone-toolbar {
        margin-left: 1rem;
        display: flex;
        flex-wrap: nowrap;
  
        .dropzone-cancel,
        .dropzone-delete,
        .dropzone-start {
          height: 25px;
          width: 25px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
  
          i {
            font-size: 0.8rem;
            color: $headings-color;
          }
  
          &:hover {
            i {
              color: $primary;
            }
          }
        }
  
        .dropzone-start {
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
  
  // dropzone end
  // uppy start
  
  .uppy-Dashboard--modal {
    z-index: 1030;
  
    .uppy-Dashboard-overlay {
      z-index: 1030;
    }
  
    .uppy-Dashboard-inner {
      z-index: 1031;
    }
  }
  
  .uppy-DragDrop--is-dragdrop-supported {
    border-color: $body-bg;
  }
  
  .dash-uppy-thumbnail-container {
    .dash-uppy-thumbnail {
      img {
        width: 100px;
      }
    }
  }
  
  // uppy end
  
  .slider-selection {
    box-shadow: none;
  }
  
  .dash-toggle-noUiSlider {
    height: 50px;
  }
  
  .dash-toggle-noUiSlider.off .noUi-handle {
    border-color: $danger;
    background: $danger;
    box-shadow: none;
  }
  
  .note-editor {
    &.card {
      .card-header {
        padding: 0 5px 5px;
        border-bottom-color: #a9a9a9;
      }
    }
  }
  
  #cke5-inline-demo {
    .ck-content {
      margin-bottom: 1rem;
      padding: 2.5rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
  
      h3 {
        margin-top: 0;
      }
    }
  
    header.ck-content {
      text-align: center;
  
      h2 {
        &:first-of-type {
          margin-top: 0;
        }
  
        & + h3 {
          margin-top: 0;
          color: $body-color;
  
          font: {
            weight: 600;
          }
        }
      }
    }
  
    .demo-row {
      width: 100%;
      display: flex;
  
      .demo-row__half {
        width: 50%;
  
        &:first-of-type {
          padding-right: 0.5rem;
        }
  
        &:last-of-type {
          padding-left: 0.5rem;
        }
      }
  
      p {
        margin: {
          bottom: 0;
        }
      }
  
      h3 {
        margin: 0;
  
        font: {
          weight: 600;
        }
      }
    }
  }
  
  // switch v1 start
  
  .switch-demo {
    .custom-switch-v1 {
      margin-bottom: 4px;
    }
  }
  
  .custom-switch-v1 {
    &.form-switch {
      padding-left: 2.9em;
  
      .form-check-input {
        height: 20px;
        width: 35px;
        margin-left: -2.9em;
        background-image: escape-svg(
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='#{$form-switch-color}'/%3e%3c/svg%3e")
        );
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  
        &[class*="input-light-"] {
          border: none;
        }
  
        &:focus {
          box-shadow: none;
          border-color: rgba(0, 0, 0, 0.25);
        }
  
        &:checked {
          background-image: escape-svg(
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e")
          );
        }
      }
  
      @each $color, $value in $theme-colors {
        .form-check-input.input-light-#{$color} {
          &:checked {
            background-image: escape-svg(
              url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='#{$value}'/%3e%3c/svg%3e")
            );
          }
        }
        .form-check-input.input-grd-#{$color} {
          &:checked {
              background-image: escape-svg(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e")) ,linear-gradient(141.55deg, $value, adjust-hue($value, 60deg))!important;
              border-color: $value !important;
          }
        }
      }
    }
  
    // ===========
    .custom-control-label {
      &::before {
        transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
        height: 20px;
        width: 35px;
        border-radius: 0.8rem;
        top: 0;
        left: -2.55rem;
      }
  
      &::after {
        top: calc(0.15625rem - 2px);
        left: calc(-2.25rem - 4px);
        height: 19px;
        width: 19px;
        border-radius: 0.7rem;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
          0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      }
    }
  
    .custom-control-input {
      &:checked ~ .custom-control-label::after {
        transform: translateX(0.95rem);
      }
    }
  
    // ===========
  }
  
  // switch v1 end
  
  .row[data-multiselectsplitter-wrapper-selector] {
    select {
      margin-bottom: 8px;
    }
  }
  
  // ============================
  // chioice selector start
  $choices-selector: "choices" !default;
  $choices-font-size-lg: 16px !default;
  $choices-font-size-md: 14px !default;
  $choices-font-size-sm: 12px !default;
  $choices-guttering: 5px !default;
  $choices-border-radius: $input-border-radius !default;
  $choices-border-radius-item: $input-border-radius !default;
  $choices-bg-color: $input-bg !default;
  $choices-bg-color-disabled: $input-disabled-bg !default;
  $choices-bg-color-dropdown: $input-bg !default;
  $choices-text-color: $input-color !default;
  $choices-keyline-color: $input-border-color !default;
  $choices-primary-color: $primary !default;
  $choices-disabled-color: $input-disabled-bg !default;
  $choices-highlight-color: $choices-primary-color !default;
  $choices-button-dimension: 8px !default;
  $choices-button-offset: 8px !default;
  $choices-icon-cross: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==") !default;
  $choices-icon-cross-inverse: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==") !default;
  
  .#{$choices-selector} {
    position: relative;
    margin-bottom: $choices-guttering;
    font-size: $choices-font-size-lg;
  
    &:focus {
      outline: none;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  
    &.is-disabled {
      .#{$choices-selector}__inner,
      .#{$choices-selector}__input {
        background-color: $choices-bg-color-disabled;
        cursor: not-allowed;
        user-select: none;
      }
  
      .#{$choices-selector}__item {
        cursor: not-allowed;
      }
    }
  
    [hidden] {
      display: none !important;
    }
  }
  
  .#{$choices-selector}[data-type*="select-one"] {
    cursor: pointer;
  
    .#{$choices-selector}__inner {
      padding-bottom: 7.5px;
    }
  
    .#{$choices-selector}__input {
      display: block;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid $choices-keyline-color;
      background-color: $input-bg;
      margin: 0;
    }
  
    .#{$choices-selector}__button {
      background-image: $choices-icon-cross-inverse;
      padding: 0;
      background-size: 8px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
      margin-right: 25px;
      height: 20px;
      width: 20px;
      border-radius: 10em;
      opacity: 0.5;
  
      &:focus,
      &:hover {
        opacity: 1;
      }
  
      &:focus {
        box-shadow: 0 0 0 2px $choices-highlight-color;
      }
    }
  
    .#{$choices-selector}__item[data-value=""] .#{$choices-selector}__button {
      display: none;
    }
  
    &:after {
      content: "";
      height: 0;
      width: 0;
      border-style: solid;
      border-color: $choices-text-color transparent transparent transparent;
      border-width: 5px;
      position: absolute;
      right: 11.5px;
      top: 50%;
      margin-top: -2.5px;
      pointer-events: none;
    }
  
    &.is-open:after {
      border-color: transparent transparent $choices-text-color transparent;
      margin-top: -7.5px;
    }
  
    &[dir="rtl"] {
      &:after {
        left: 11.5px;
        right: auto;
      }
  
      .#{$choices-selector}__button {
        right: auto;
        left: 0;
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }
  
  .#{$choices-selector}[data-type*="select-multiple"],
  .#{$choices-selector}[data-type*="text"] {
    .#{$choices-selector}__inner {
      cursor: text;
    }
  
    .#{$choices-selector}__button {
      position: relative;
      display: inline-block;
      margin: 0 #{-$choices-button-offset/2} 0 #{$choices-button-offset};
      padding-left: $choices-button-offset * 2;
      border-left: $input-border-width solid rgba($white, 0.35);
      background-image: $choices-icon-cross;
      background-size: $choices-button-dimension;
      width: $choices-button-dimension;
      line-height: 1;
      opacity: 0.75;
      border-radius: 0;
  
      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .#{$choices-selector}__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: $choices-bg-color;
    padding: 7.5px 7.5px 3.75px;
    border: $input-border-width solid $choices-keyline-color;
    border-radius: $choices-border-radius;
    font-size: $choices-font-size-md;
    min-height: 44px;
    overflow: hidden;
  
    .is-focused &,
    .is-open & {
      border-width: 2px;
      border-color: $primary;
    }
  
    .is-open & {
      border-radius: $choices-border-radius $choices-border-radius 0 0;
    }
  
    .is-flipped.is-open & {
      border-radius: 0 0 $choices-border-radius $choices-border-radius;
    }
  }
  
  .#{$choices-selector}__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  
  .#{$choices-selector}__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;
  
    [dir="rtl"] & {
      padding-right: 4px;
      padding-left: 16px;
    }
  
    .#{$choices-selector}__item {
      width: 100%;
    }
  }
  
  .#{$choices-selector}__list--multiple {
    display: inline;
  
    .#{$choices-selector}__item {
      display: inline-block;
      vertical-align: middle;
      border-radius: $choices-border-radius-item;
      padding: 4px 10px;
      font-size: $choices-font-size-sm;
      font-weight: 500;
      margin-right: 3.75px;
      margin-bottom: 3.75px;
      background-color: $choices-primary-color;
      border: 1px solid darken($choices-primary-color, 5%);
      color: #ffffff;
      word-break: break-all;
      box-sizing: border-box;
  
      &[data-deletable] {
        padding-right: 5px;
      }
  
      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 3.75px;
      }
  
      &.is-highlighted {
        background-color: darken($choices-primary-color, 5%);
        border: 1px solid darken($choices-primary-color, 10%);
      }
  
      .is-disabled & {
        background-color: darken($choices-disabled-color, 25%);
        border: 1px solid darken($choices-disabled-color, 35%);
      }
    }
  }
  
  .#{$choices-selector}__list--dropdown {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: $choices-bg-color-dropdown;
    border: 1px solid $choices-keyline-color;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: $choices-border-radius;
    border-bottom-right-radius: $choices-border-radius;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
  
    &.is-active {
      visibility: visible;
    }
  
    .is-open & {
      border-color: darken($choices-keyline-color, 15%);
    }
  
    .is-flipped & {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: -1px;
      border-radius: 0.25rem 0.25rem 0 0;
    }
  
    .#{$choices-selector}__list {
      position: relative;
      max-height: 300px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      will-change: scroll-position;
    }
  
    .#{$choices-selector}__item {
      position: relative;
      padding: 10px;
      font-size: $choices-font-size-md;
  
      [dir="rtl"] & {
        text-align: right;
      }
    }
  
    .#{$choices-selector}__item--selectable {
      @media (min-width: 640px) {
        padding-right: 100px;
  
        &:after {
          content: attr(data-select-text);
          font-size: $choices-font-size-sm;
          opacity: 0;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
  
        [dir="rtl"] & {
          text-align: right;
          padding-left: 100px;
          padding-right: 10px;
  
          &:after {
            right: auto;
            left: 10px;
          }
        }
      }
  
      &.is-highlighted {
        background-color: transparentize($primary, 0.9);
  
        &:after {
          opacity: 0.5;
        }
      }
    }
  }
  
  .#{$choices-selector}__item {
    cursor: default;
  }
  
  .#{$choices-selector}__item--selectable {
    cursor: pointer;
  }
  
  .#{$choices-selector}__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
  }
  
  .#{$choices-selector}__heading {
    font-weight: 600;
    font-size: $choices-font-size-sm;
    padding: 10px;
    border-bottom: 1px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
  }
  
  .#{$choices-selector}__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  
    &:focus {
      outline: none;
    }
  }
  
  .#{$choices-selector}__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: $choices-bg-color;
    font-size: $choices-font-size-md;
    margin-bottom: 0px;
    border: 0;
    color: $input-color;
    border-radius: 0;
    max-width: 100%;
    padding: 4px 0 4px 2px;
  
    &:focus {
      outline: 0;
    }
  
    [dir="rtl"] & {
      padding-right: 2px;
      padding-left: 0;
    }
  }
  
  .#{$choices-selector}__placeholder {
    opacity: 0.5;
  }
  
  // chioice selector end
  // ============================
  // type-ahead css start
  .typeahead {
    position: relative;
  
    & > ul {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
      display: none;
      float: left;
      font-size: 14px;
      left: 0;
      list-style: none;
      margin: 2px 0 0;
      min-width: 170px;
      padding: 5px 0;
      position: absolute;
      text-align: left;
      top: 100%;
      z-index: 1000;
  
      > li {
        > a {
          clear: both;
          color: #333;
          cursor: default;
          display: block;
          font-weight: 400;
          line-height: 1.42857143;
          padding: 3px 20px;
          white-space: nowrap;
        }
  
        &.active > a,
        > a:hover {
          background-color: #3f9bfa;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
  .validate-me {
    .error {
      border-color: $danger;
    }
  }
  
  .error-message {
    color: $danger;
  }
  
  // ============================
  // Form css end
  // ============================
  .custom-select,
  .dataTable-selector,
  .dataTable-input{
    @extend .form-control
  }